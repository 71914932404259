<template>
  <b-container>
    <b-row>
      <b-col>
        <pre v-html="JSON.stringify(this.scheda, null, 2)" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { service_handler } from '@/services'
export default {
  name: 'scheda',
  data() {
    return {
      scheda: {}
    }
  },
  props: {
    codScheda: String
  },
  mounted() {
    this.get_scheda()
  },
  methods:
  {
    get_scheda() {
      let parameters = {
        codScheda: this.codScheda
      }
      service_handler(
        'scheda',
        parameters,
      )
        .then(
          (success) => {
            this.log('[scheda.vue] - success')
            this.log(success.data)

            this.scheda = success.data.scheda

          },
          (error) => {
            this.log('[scheda.vue] - error')
            this.log(error.response)

          }
        )

    }
  }
}
</script>